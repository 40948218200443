import React, { useState, Alert } from "react";

import { BASE_URL } from "../../utilities";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState({});

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const params = useParams();
  const id = params?.id || "";

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const handleSubmit = async (event) => {
    //Prevent page reload
    setSuccess("");
    event.preventDefault();

    if (event.target.password.value !== event.target.confirm_password.value) {
      setError("Password and Confirm Password not matched");
      return;
    }

    const body = {
      password: event.target.password.value.trim(),
      id: id,
    };

    await axios({
      method: "post",
      url: `${BASE_URL}/auth/reset-password`,
      data: body,
    })
      .then((response) => {
        setError("");
        setSuccess("Password updated successfully");

        // alert("Password updated successfully");
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        setSuccess("");
      });
  };

  return (
    <div>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Reset Your Password</h3>

            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                required
                name="password"
              />
            </div>
            <div className="form-group mt-3">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Confirm password"
                required
                name="confirm_password"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary button-style">
                Submit
              </button>
            </div>
            <p className="success-message"> {success}</p>
            <p className="error-message"> {error}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
