import { CardHeader, makeStyles } from "@material-ui/core";
import { ListItemIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "styled-components";
import { logo, notification, sideBarItems } from "../../utilities";
import { Avatar } from "antd";
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  },
  mobiel_drawer: {
    width: 80,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 70,
      boxSizing: "border-box",
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  avatar: {
    marginRight: theme.spacing(1),
    height: 40,
    width: 40,
  },
}));

export default function SideNav(props) {
  const [selected, setSelected] = useState({ id: 0 });
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  const classes = useStyles();
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  React.useEffect(() => {
    // Get the last selected screen from local storage
    const lastSelectedScreen = localStorage.getItem("selectedScreen");
    if (lastSelectedScreen) {
      setSelected(lastSelectedScreen);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F5F5" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
            <div className="aiRow">
              <div>
                <img src={logo} height={42} width={42}></img>
              </div>
              InnoventSoft
            </div>
          </Typography>
          <div className="navDropdown justify-content-end">
            {/* <div onClick={() => {}} className={classes.root}>
              <img src={notification} height={20} width={20} />
            </div> */}
            <div className={classes.root}>
              <Avatar className={classes.avatar} />
              <span style={{ display: "grid" }}>
                <Typography variant="subtitle1" component="h6">
                  {"Hawkins Maru"}
                </Typography>
                <Typography
                  style={{ marginTop: -5 }}
                  variant="caption"
                  color="textSecondary"
                >
                  {"Company Manager"}
                </Typography>
              </span>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(mobileView ? classes.mobiel_drawer : classes.drawer)}
      >
        {/* <Toolbar /> */}
        <Box sx={{ overflow: "auto", margin: 1 }}>
          <List>
            {sideBarItems.map((item, index) => (
              <NavLink
                className={
                  selected == item?.title ? "activeRoute" : "nonActiveRoute"
                }
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to={item?.path}
                key={index.toString()}
              >
                <ListItem
                  key={item}
                  disablePadding
                  className={
                    selected == item?.title
                      ? `navButtonStyleSelected`
                      : "navButtonStyle"
                  }
                >
                  <ListItemButton
                    style={{ borderRadius: 12 }}
                    onClick={() => {
                      setSelected(item?.title);
                      localStorage.setItem("selectedScreen", item?.title);
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    {!mobileView && <ListItemText primary={item?.title} />}
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {props && props.children}
      </Box>
    </Box>
  );
}
