import { createSlice } from "@reduxjs/toolkit";
import { addContactAction } from "../../actions";

// initialize userToken from local storage

const initialState = {
  loading: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Business Leads Cases
    builder.addCase(addContactAction.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addContactAction.fulfilled, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(addContactAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export default contactSlice.reducer;
