import { HomePage, Login, PageNotFound, Users } from "../Pages";
import ResetPassword from "../Pages/ResetPassword";
export const My_Routes = {
  Public_Routes: [
    {
      path: "/reset-password/:id",
      component: <ResetPassword />,
    },
    {
      path: "/",
      component: <HomePage />,
    },
    {
      path: "*",
      component: <PageNotFound />,
    },
  ],

  Private_Routes: [
    // {
    //   path: "/",
    //   component: <HomePage />,
    // },
    // {
    //   path: "/dashboard",
    //   component: <HomePage />,
    // },

    // {
    //   path: "/users",
    //   component: <Users />,
    // },
    {
      path: "*",
      component: <PageNotFound />,
    },
  ],
};
