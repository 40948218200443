import { createAsyncThunk } from "@reduxjs/toolkit";
import { addContactApi } from "../../../service";
import * as Types from "../../types";

export const addContactAction = createAsyncThunk(
  Types.ADD_CONTACT,
  async ({ values, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const res = await addContactApi(values);
      onSuccess(res?.data);
      return res?.data;
    } catch (error) {
      onFailure(error?.response?.data);

      if (error.response && error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
