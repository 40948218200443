import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import {
  LineCharts,
  NoAxisLineChart,
  PieCharts,
  AppHeading,
} from "../../components";
import {
  platformColors,
  platformData,
  referralSubscribedColors,
  referralSubscribedData,
  regionColors,
  regionData,
  subscribedColors,
  subscribedData,
  summaryTokenData,
  getRecentMonths,
} from "../../utilities";

const Home = ({ navigation }) => {
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState("");

  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Genie App
    </div>
    // <Container fluid className="container">
    //   <AppHeading
    //     title={"Dashboard"}
    //     selectValue={selectValue}
    //     optionSelectData={getRecentMonths()}
    //     onChangeOptionSelect={(event) => {}}
    //   />
    //   <Row>
    //     <Col lg={4} md={4} sm={12}>
    //       <NoAxisLineChart
    //         title={"Revenue this month"}
    //         data={summaryTokenData}
    //         width={"30%"}
    //         nameKey={"name"}
    //         dataKey1={"token"}
    //         line1StrokeColor={"#4CBC9A"}
    //         optionSelectData={getRecentMonths()}
    //         onChangeOptionSelect={(event) => {
    //           setSelectValue(event.target.value);
    //         }}
    //         showGridVertical={true}
    //         h1={"$3,456"}
    //         h2={"+23%"}
    //       />
    //     </Col>
    //     <Col lg={8} md={6} sm={12}>
    //       <LineCharts
    //         title={"Token used this month"}
    //         data={summaryTokenData}
    //         width={"70%"}
    //         nameKey={"name"}
    //         dataKey1={"summary"}
    //         // dataKey2={"token"}
    //         line1StrokeColor={"rgba(251, 125, 91, 1)"}
    //         // line2StrokeColor={"rgba(15, 129, 201, 1)"}
    //         optionSelectData={getRecentMonths()}
    //         onChangeOptionSelect={(event) => {}}
    //         showGridVertical={true}
    //       />
    //     </Col>
    //   </Row>

    //   <Row>
    //     <Col lg={8} md={6} sm={12}>
    //       <LineCharts
    //         title={""}
    //         data={summaryTokenData}
    //         width={"70%"}
    //         nameKey={"name"}
    //         dataKey1={"summary"}
    //         dataKey2={"token"}
    //         line1StrokeColor={"rgba(252, 196, 62, 1)"}
    //         line2StrokeColor={"rgba(15, 129, 201, 1)"}
    //         selectValue={selectValue}
    //         optionSelectData={getRecentMonths()}
    //         onChangeOptionSelect={(event) => {}}
    //         showGridVertical={true}
    //       />
    //     </Col>
    //     <Col lg={4} md={6} sm={12}>
    //       <PieCharts
    //         title={"Subscribed Users"}
    //         data={subscribedData}
    //         chartColors={subscribedColors}
    //       />
    //     </Col>
    //   </Row>

    //   {/* <PieCharts
    //     title={"Revenue by Platforms"}
    //     data={platformData}
    //     chartColors={platformColors}
    //   />

    //   <PieCharts
    //     title={"Revenue by Regions"}
    //     data={regionData}
    //     chartColors={regionColors}
    //   />

    //   <PieCharts
    //     title={"Referals & Subscribed"}
    //     data={referralSubscribedData}
    //     chartColors={referralSubscribedColors}
    //   /> */}
    // </Container>
  );
};

export default Home;
