import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Sidenav from "../components/SideNav/Sidenav";
import { My_Routes } from "./RoutesArray";

const RouteConfig = () => {
  const isLogin = JSON.parse(localStorage.getItem("isLogin"));

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect =
      My_Routes.Public_Routes.find(
        (element) => element?.path === location?.pathname
      ) || {};
    if (redirect?.path && isLogin) {
      navigate("/");
    }
  }, [location?.pathname]);

  return (
    <>
      <Routes>
        {My_Routes.Public_Routes.map((comp, index) => (
          <Route key={index} path={comp.path} element={comp.component} />
        ))}

        {My_Routes.Private_Routes.map((comp, index) => (
          <Route
            key={index}
            path={comp.path}
            element={
              <RequireAuth>
                <Sidenav>{comp.component}</Sidenav>
              </RequireAuth>
            }
          />
        ))}
      </Routes>
    </>
  );
};
export default RouteConfig;

function RequireAuth({ children }) {
  const isLogin = JSON.parse(localStorage.getItem("isLogin"));

  if (!isLogin) {
    return <Navigate to="/" replace />;
  }
  return children;
}
