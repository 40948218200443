export const platformColors = ["rgba(0, 94, 184, 1)", "rgba(251, 176, 64, 1)"];
export const subscribedColors = [
  "rgba(60, 159, 218, 1)",
  "rgba(93, 215, 218, 1)",
];
export const referralSubscribedColors = [
  "rgba(0, 94, 184, 1)",
  "rgba(206, 206, 206, 1)",
];

export const regionColors = [
  "rgba(53, 39, 93, 1)",
  "rgba(166, 166, 166, 1)",
  "rgba(166, 155, 194, 1)",
  "rgba(113, 96, 158, 1)",
  "rgba(77, 56, 133, 1)",
];

export const platformData = [
  { name: "Android", value: 150 },
  { name: "IOS", value: 300 },
];

export const subscribedData = [
  { name: "Classic Plan", value: 2000 },
  { name: "Prime Plan", value: 700 },
];

export const regionData = [
  { name: "India", value: 2000 },
  { name: "USA", value: 700 },
  { name: "UK", value: 200 },
  { name: "Others", value: 200 },
];

export const referralSubscribedData = [
  { name: "Referrals", value: 2000 },
  { name: "Subscribed", value: 700 },
];

export const summaryTokenData = [
  { name: "Jan", summary: 4000, token: 2400 },
  { name: "Feb", summary: 3000, token: 1398 },
  { name: "Mar", summary: 2000, token: 9800 },
  { name: "Apr", summary: 2780, token: 3908 },
  { name: "May", summary: 1890, token: 4800 },
  { name: "Jun", summary: 2390, token: 3800 },
  { name: "Jul", summary: 3490, token: 4300 },
  { name: "Aug", summary: 4000, token: 2400 },
  { name: "Sep", summary: 3000, token: 1398 },
  { name: "Oct", summary: 2000, token: 9800 },
  { name: "Nov", summary: 2780, token: 3908 },
  { name: "Dec", summary: 1890, token: 4800 },
];
export const sideBarItems = [
  {
    id: 0,
    title: "Dashboard",
    icon: (
      <svg
        className="svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="activeSvg"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5ZM6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15ZM6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16Z"
          fill="#808191"
        />
      </svg>
    ),
    path: "/dashboard",
  },
  {
    id: 1,
    title: "Users",
    icon: (
      <svg
        className="svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7575 4.87216L6.75746 6.37216C6.3123 6.48345 6 6.88343 6 7.3423V20H14V9.99997V7.99997V5.8423C14 5.19173 13.3886 4.71437 12.7575 4.87216ZM16 9.99997V20H18V11C18 10.4477 17.5523 9.99997 17 9.99997H16ZM20 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H20V11C20 9.34312 18.6569 7.99997 17 7.99997H16V5.8423C16 3.89059 14.1658 2.45851 12.2724 2.93187L6.27239 4.43187C4.93689 4.76575 4 5.9657 4 7.3423V20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H4H6H14H16H18H20Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 17C8 16.4477 8.44772 16 9 16H11C11.5523 16 12 16.4477 12 17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H9C8.44772 14 8 13.5523 8 13Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9C8 8.44772 8.44772 8 9 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H9C8.44772 10 8 9.55228 8 9Z"
          fill="#808191"
        />
      </svg>
    ),
    path: "/users",
  },
  {
    id: 2,
    title: "Tokens used",
    icon: (
      <svg
        className="svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4998 10.9692C16.4731 10.7233 18.0001 9.04002 18.0001 7.0001C18.0001 4.96018 16.4731 3.27692 14.4998 3.03101C15.4335 4.08875 16.0001 5.47826 16.0001 7.0001C16.0001 8.52194 15.4335 9.91145 14.4998 10.9692Z"
          fill="#6F767E"
        />
        <path
          d="M20 20.0001C20 20.5524 20.4477 21.0001 21 21.0001C21.5523 21.0001 22 20.5524 22 20.0001V18.0001C22 15.34 19.9227 13.1651 17.3018 13.009C18.3539 13.9408 19.1587 15.1454 19.6055 16.5121C19.8565 16.9506 20 17.4586 20 18.0001V20.0001Z"
          fill="#6F767E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 15C5.34315 15 4 16.3431 4 18V20C4 20.5523 3.55228 21 3 21C2.44772 21 2 20.5523 2 20V18C2 15.2386 4.23858 13 7 13H13C15.7614 13 18 15.2386 18 18V20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20V18C16 16.3431 14.6569 15 13 15H7Z"
          fill="#6F767E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9C11.1046 9 12 8.10457 12 7C12 5.89543 11.1046 5 10 5ZM6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7Z"
          fill="#6F767E"
        />
      </svg>
    ),
    path: "/",
  },
  {
    id: 3,
    title: "Revenue",
    icon: (
      <svg
        className="svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2C3.55228 2 4 2.44772 4 3V19C4 19.5523 4.44772 20 5 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 8C7.55228 8 8 8.44772 8 9V17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17V9C6 8.44772 6.44772 8 7 8Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 14C11.5523 14 12 14.4477 12 15V17C12 17.5523 11.5523 18 11 18C10.4477 18 10 17.5523 10 17V15C10 14.4477 10.4477 14 11 14Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 4C15.5523 4 16 4.44772 16 5V17C16 17.5523 15.5523 18 15 18C14.4477 18 14 17.5523 14 17V5C14 4.44772 14.4477 4 15 4Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 10C19.5523 10 20 10.4477 20 11V17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17V11C18 10.4477 18.4477 10 19 10Z"
          fill="#808191"
        />
      </svg>
    ),
    path: "/",
  },
  {
    id: 4,
    title: "Referrals",
    icon: (
      <svg
        className="svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9457 7.22016L12.0001 4.5L11.0546 7.22016C10.5053 8.80044 9.03054 9.87192 7.35786 9.90601L4.47865 9.96468L6.77349 11.7045C8.10669 12.7153 8.67 14.449 8.18553 16.0503L7.35161 18.8067L9.71542 17.1618C11.0887 16.2062 12.9116 16.2062 14.2849 17.1618L16.6487 18.8067L15.8148 16.0503C15.3303 14.449 15.8936 12.7153 17.2268 11.7045L19.5216 9.96468L16.6424 9.90601C14.9697 9.87192 13.495 8.80044 12.9457 7.22016ZM13.8893 3.84334C13.2667 2.05222 10.7336 2.05222 10.111 3.84334L9.16549 6.5635C8.89084 7.35364 8.15345 7.88938 7.31711 7.90642L4.4379 7.9651C2.54205 8.00373 1.7593 10.4128 3.27036 11.5584L5.5652 13.2983C6.2318 13.8036 6.51345 14.6705 6.27122 15.4712L5.4373 18.2276C4.88819 20.0426 6.93749 21.5315 8.49397 20.4484L10.8578 18.8035C11.5444 18.3257 12.4559 18.3257 13.1425 18.8035L15.5063 20.4484C17.0628 21.5315 19.1121 20.0426 18.563 18.2276L17.7291 15.4712C17.4868 14.6705 17.7685 13.8036 18.4351 13.2983L20.7299 11.5584C22.241 10.4128 21.4582 8.00373 19.5624 7.9651L16.6832 7.90642C15.8468 7.88938 15.1094 7.35364 14.8348 6.5635L13.8893 3.84334Z"
          fill="#808191"
        />
      </svg>
    ),
    path: "/",
  },
  // {
  //   id: 5,
  //   title: "Issues Raised",
  //   icon: (
  //     <svg
  //       className="svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12ZM12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
  //         fill="#808191"
  //       />
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M18.5588 19.5488C20.6672 17.7154 22 15.0134 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.0134 3.33284 17.7154 5.44116 19.5488C7.19693 21.0756 9.49052 22 12 22C14.4162 22 16.6323 21.143 18.3609 19.7165C18.4276 19.6614 18.4936 19.6055 18.5588 19.5488ZM12.2579 19.9959C12.1723 19.9986 12.0863 20 12 20C11.9914 20 11.9827 20 11.9741 20C11.8937 19.9997 11.8135 19.9983 11.7337 19.9956C10.3914 19.9517 9.13273 19.5772 8.03655 18.9508C8.95181 17.7632 10.3882 17 12 17C13.6118 17 15.0482 17.7632 15.9634 18.9508C14.865 19.5785 13.6033 19.9533 12.2579 19.9959ZM17.5624 17.7498C16.2832 16.0781 14.2675 15 12 15C9.73249 15 7.7168 16.0781 6.43759 17.7498C4.93447 16.2953 4 14.2568 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.2568 19.0655 16.2953 17.5624 17.7498Z"
  //         fill="#808191"
  //       />
  //     </svg>
  //   ),
  //   path: "/",
  // },
];
