import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Types from '../../types';
// userActions.js
export const loginAction = createAsyncThunk(
  Types.AUTH_LOGIN,
  async ({ values, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      // const requestBody = {
      //   username: '',
      //   password: '',
      // };
      // const res = await loginApi(requestBody);
      // console.log(res);
      localStorage.setItem('token', 'auth_token');

      onSuccess(values);
      return values;
    } catch (error) {
      onFailure(error);
      // console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Logout
export const logoutAction = createAsyncThunk(
  Types.AUTH_LOGOUT,
  async ({ values, callBack }, { rejectWithValue }) => {
    try {
      callBack(values);
      return values;
    } catch (error) {
      // console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
