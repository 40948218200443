import { IconButton, TextField, makeStyles } from "@material-ui/core";
import { Delete, Edit, Search, Visibility } from "@material-ui/icons";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from "react";
import { Col } from "react-bootstrap";

const columns = [
  { id: "id", label: "ID", minWidth: 10 },
  { id: "name", label: "Name", minWidth: 10 },
  { id: "email", label: "Email", minWidth: 10 },
  {
    id: "member_since",
    label: "Member since",
    minWidth: 70,
  },
  {
    id: "subscription",
    label: "Subscription",
    minWidth: 10,
  },
  {
    id: "last_active",
    label: "Last Active",
    minWidth: 10,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 10,
  },
];

function createData(
  id,
  name,
  email,
  member_since,
  subscription,
  last_active,
  action
) {
  return { id, name, email, member_since, subscription, last_active };
}

const rows = [
  createData(
    "1",
    "Umer",
    "test@gmail.com",
    "12, Jan 2020",
    "Not Subscribed",
    "10:30 AM 12, Feb 2020"
  ),
  createData(
    "2",
    "Zia",
    "test@gmail.com",
    "12, Jan 2020",
    "Classic member Crdits: 200",
    "10:30 AM 12, Feb 2020"
  ),
  createData(
    "3",
    "Mehraj",
    "test@gmail.com",
    "12, Jan 2020",
    "Not Subscribed",
    "10:30 AM 12, Feb 2020"
  ),
  createData(
    "4",
    "Faheem",
    "test@gmail.com",
    "12, Jan 2020",
    "Prime member Crdits: 200",
    "10:30 AM 12, Feb 2020"
  ),
];

export function UserTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Col>
        <TextField
          label="Search"
          placeholder="Type Something!"
          margin="normal"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            style: { fontSize: 14 },
            startAdornment: <Search />,
          }}
        />
      </Col>

      <Paper className={"paperCon"}>
        <TableContainer className={"tableCon"}>
          <Table>
            <TableHead>
              <TableRow className={"tableHeader"}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={"tableHeadCell"}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      style={{
                        backgroundColor: index % 2 ? "#FFFFFF" : "transparent",
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            className={"tableCell"}
                            key={column.id}
                            align={column.align}
                          >
                            {column?.id == "action" ? (
                              <React.Fragment>
                                <IconButton>
                                  <Visibility style={{ fontSize: 18 }} />
                                </IconButton>
                                <IconButton>
                                  <Edit
                                    style={{ fontSize: 18, color: "#1882FF" }}
                                  />
                                </IconButton>
                                <IconButton>
                                  <Delete
                                    style={{ fontSize: 18, color: "#FF4646" }}
                                  />
                                </IconButton>
                              </React.Fragment>
                            ) : column?.id == "subscription" ? (
                              <div
                                style={{
                                  padding: 5,
                                  backgroundColor:
                                    value == "Not Subscribed"
                                      ? "#FFEAEA"
                                      : value?.match("Prime member")
                                      ? "#E0FFE1"
                                      : "#DFF4FF",
                                  borderRadius: 50,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 12,
                                    color:
                                      value == "Not Subscribed"
                                        ? "#FF4646"
                                        : value?.match("Prime member")
                                        ? "#1DA611"
                                        : "#1882FF",
                                  }}
                                >
                                  {value}{" "}
                                </p>
                              </div>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
